import React from 'react';

import { isNil } from 'lodash';
import { toast } from 'react-semantic-toasts';
import { request } from '@jvs-group/jvs-mairistem-store';
import { isNewIdentifiant, newIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import {
  Button, Modal, Table, Popup, Menu,
} from '@jvs-group/jvs-mairistem-composants';
import { formatMontant, type Gabarit } from '@jvs-group/jvs-mairistem-finances-utils';
import dayjs from 'dayjs';
import { DropdownOption } from '@jvs-group/jvs-mairistem-liste';
import { toDateString } from '@/src/utils/tools';
import AssistantVersements, { STEP_CARACTERISTIQUE }
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/AssistantVersements';
import { URL_PARAMETRES_ENTITES } from '@/src/utils/url';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import '@/src/components/SubventionFichePanel/ConditionsVersements/conditionVersement.css';
import { Subvention } from '@/src/interfaces/subvention';
import { PieceComptabilite } from '@/src/interfaces/pieceComptabilite';
import PreparationModal from '@/src/components/SubventionFichePanel/ConditionsVersements/PreparationModal';
import renderEtatIcons from '@/src/utils/etatsIcons';
import ConditionVersementEtat from '@/src/constants/ConditionVersementEtat';

const LIBELLE_TYPE_PIECE = {
  M: 'mouvement',
  E: 'engagement',
};

interface ConditionsVersementsProps {
  montantSubvention: number,
  conditionsVersements: Array<ConditionVersement>,
  subvention: Subvention,
  identifiant: number,
  deletedConditionsVersements: Array<number>,
  onChange: any,
  identifiantEntite: number,
  gabaritsPj: Gabarit[],
}

interface PieceDemande {
  identifiantExercice: number,
  identifiantPiece: number,
  typePiece: string,
}

const ConditionsVersements = (
  {
    identifiantEntite = null,
    montantSubvention = 0,
    conditionsVersements = [],
    subvention = null,
    identifiant = null,
    deletedConditionsVersements = [],
    onChange = null,
    gabaritsPj = null,
  }: ConditionsVersementsProps,
) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [openConditionMenuId, setOpenConditionMenuId] = React.useState<number |string>(null);
  const [currentCondition, setCurrentCondition] = React.useState(null);
  const [assistantStep, setAssistantStep] = React.useState(STEP_CARACTERISTIQUE);
  const [budgetOptions, setBudgetOptions] = React.useState<DropdownOption[]>([]);
  // information de la piece cree dans le but de l ouvrir dans la compta
  const [pieceDemande, setPieceDemande] = React.useState<Partial<PieceDemande>>({});
  const [openPreparationDemande, setOpenPreparationDemande] = React.useState(false);
  const [loadingOpenComptabilite, setLoadingOpenComptabilite] = React.useState(false);
  const preparationModalRef = React.useRef(document.getElementById('root')?.firstChild?.lastChild?.firstChild);

  React.useEffect(() => {
    if (!isNil(identifiantEntite)) {
      // TODO: Typage de l'entite
      request.get<unknown, any>(
        `${URL_PARAMETRES_ENTITES}?identifiantEntiteSubvention=${identifiantEntite}`,
      ).then(({ data }) => {
        setBudgetOptions(
          data.map((budget) => (
            {
              key: budget.identifiantEntite,
              text: `${budget.raisonSociale}`,
              value: budget.identifiantEntite,
              'data-testid': `budgetOption-${budget.identifiantEntite}`,
            }
          )),
        );
      });
    }
  }, [identifiantEntite]);

  const getExecutionComptable = (mouvements, engagements) => {
    if (mouvements?.length > 0) {
      return 'Titré';
    }

    if (engagements?.length > 0) {
      return 'Engagé';
    }

    return '';
  };

  const getRegle = (condition) => {
    switch (condition.pourcentageExecution) {
      case 0:
        return 'au lancement du projet';
      case 100:
        return 'à la fin de réalisation du projet';
      default:
        return `dès ${condition.pourcentageExecution}% de réalisation du projet`;
    }
  };

  const handleFinish = (versement: ConditionVersement) => {
    // on gere l'ajout d'une nouvelle condition
    if (currentCondition === null) {
      onChange({
        identifiant,
        conditionsVersements: [...conditionsVersements, versement],
      });
    } else {
      // on gere la mise a jour d'une condition
      onChange({
        identifiant,
        conditionsVersements: conditionsVersements.map((v) => {
          if (v.identifiant === versement.identifiant) {
            return versement;
          }

          return v;
        }),
      });
    }

    setOpenModal(false);
  };

  const showPreparationDemande = (condition) => {
    setOpenConditionMenuId(null);
    setOpenPreparationDemande(true);
    setCurrentCondition(condition);
  };

  const handleEnvoieDemande = (condition) => {
    setOpenConditionMenuId(null);
    if (isNil(condition?.imputationsVersements) || condition?.imputationsVersements?.length === 0) {
      toast({
        title: 'Veuillez renseigner une imputation pour ce versement',
        type: 'error',
        time: 5000,
      });

      return;
    }

    request.post(`/api/finances/subventionsPercues/conditionVersement/${condition?.identifiant}/envoie`)
      .then((data) => {
        setPieceDemande(data);
      }).catch((e) => {
        if (e.code === 400) {
          toast({
            title: e?.message?.[0]?.message?.[0] ?? "Impossible d'effectuer la demande",
            type: 'error',
            time: 5000,
          });
        } else {
          toast({
            title: "Impossible d'effectuer la demande",
            type: 'error',
            time: 5000,
          });
        }
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openAssistantVersement = (currentCond = null, step = STEP_CARACTERISTIQUE) => {
    setOpenConditionMenuId(null);
    if (currentCond === null) {
      const totalConditions = conditionsVersements.reduce(
        (previous, current) => ({ montant: previous.montant + current.montant }),
        { montant: 0 },
      );

      if (totalConditions.montant >= montantSubvention) {
        toast({
          title: 'Création impossible',
          // eslint-disable-next-line max-len
          description: 'Création d\'un versement impossible, le montant de la subvention est déjà entièrement réparti sur les versements existants',
          type: 'error',
          time: 5000,
        });

        return;
      }
    }
    setAssistantStep(step);
    setCurrentCondition(currentCond);
    setOpenModal(true);
  };

  const getCurrentCondition = React.useCallback(() => {
    if (currentCondition === null) {
      const restant = conditionsVersements.reduce(
        (previous, current) => ({ montant: previous.montant + current.montant }),
        { montant: 0 },
      );

      const pourcentageSubvention = ((montantSubvention - restant.montant) * 100) / montantSubvention;
      const {
        identifiantEntiteCompta,
        identifiantSubvention,
        typeBudget,
        annee,
      } = conditionsVersements[conditionsVersements?.length ?? 0 - 1] ?? {};

      return {
        identifiant: newIdentifiant(),
        annee: annee ?? new Date().getFullYear(),
        pourcentageCumuleSubvention: 100,
        montant: montantSubvention - restant.montant,
        pourcentageSubvention,
        identifiantEntite,
        identifiantEntiteCompta,
        identifiantSubvention,
        typeBudget,
        pourcentageExecution: 0,
        deletedNaturesConditions: [],
        naturesConditions: [],
      };
    }

    return conditionsVersements[currentCondition];
  }, [conditionsVersements, currentCondition, identifiantEntite, montantSubvention]);

  const handleDeleteCondition = (identifiantConditionVersement) => {
    setOpenConditionMenuId(null);
    if (!isNil(identifiantConditionVersement) && !isNewIdentifiant(identifiantConditionVersement)) {
      onChange({
        identifiant,
        deletedConditionsVersements: [...deletedConditionsVersements, identifiantConditionVersement],
      });
    }

    onChange({
      identifiant,
      conditionsVersements: conditionsVersements.filter((c) => c.identifiant !== identifiantConditionVersement),
    });
  };

  const getMontantPrecedentesConditions = React.useCallback(() => {
    let montant = 0;
    conditionsVersements.forEach((e, index) => {
      if (currentCondition === null || index < currentCondition) {
        montant += e.montant;
      }
    });

    return montant;
  }, [conditionsVersements, currentCondition]);

  const handleLibelleDatalist = (): string[] => {
    const indexCondition = currentCondition ?? conditionsVersements.length;
    let accompteCount = 0;
    for (let i = 0; i < indexCondition; i++) {
      if (conditionsVersements[i]?.libelle.toUpperCase().includes('ACOMPTE')) {
        accompteCount += 1;
      }
    }

    const result = [];
    if (indexCondition === 0) {
      result.push('Avance');
      result.push('1er acompte');
      result.push('Solde');
      result.push('Versement unique');
    } else if (accompteCount > 0) {
      result.push(`${accompteCount + 1} ème acompte`);
      result.push('Solde');
    } else {
      result.push('1er acompte');
      result.push('Solde');
    }

    return result;
  };

  const getBugetLibelle = (identifiantEntiteCompta) => {
    const budget = budgetOptions.find((b) => b.value === identifiantEntiteCompta);
    return budget?.text;
  };

  const getLibelleImputation = (imputation) => {
    if (isNil(imputation) || isNil(imputation?.libelle)) {
      return '';
    }

    return `R ${imputation?.section} ${imputation?.codeChapitre} ${imputation?.libelle}`;
  };

  const hanldeCloseModalComptabilite = () => {
    setPieceDemande({});
  };

  const handleOpenComptabilite = () => {
    setPieceDemande({});
    const params = new URLSearchParams();
    params.append('identifiantExercice', pieceDemande?.identifiantExercice?.toString());
    params.append('identifiantPiece', pieceDemande?.identifiantPiece?.toString());
    params.append('typePiece', pieceDemande?.typePiece);
    params.append('idClient', sessionStorage.getItem('idClient'));
    params.append('component', sessionStorage.getItem('component'));

    setLoadingOpenComptabilite(true);
    request.get<unknown, PieceComptabilite>(
      `/api/finances/subventionsPercues/conditionVersement/open?${params.toString()}`,
    ).then((data) => {
      if (data?.url) {
        window.open(data?.url, '_blank');
      } else {
        toast({
          title: 'Visualisation impossible',
          description: "Erreur lors de la visualisation de l'écriture.",
          type: 'error',
          time: 5000,
        });
      }
    }).finally(() => {
      setLoadingOpenComptabilite(false);
    });
  };

  // On met à jour l'état et la date de demande de la condition
  const handleDownloadSuccess = (condition: ConditionVersement) => {
    onChange({
      identifiant,
      conditionsVersements: conditionsVersements.map((v) => {
        if (v.identifiant === condition.identifiant) {
          return {
            ...v,
            etat: ConditionVersementEtat.VERSEMENT_DEMANDE,
            dateDemande: dayjs().format('YYYY-MM-DD'),
          };
        }

        return v;
      }),
    }, true);
  };

  return (
    <>
      <Table
        celled
        striped
        id="versementsTable"
        data-testid="conditionVersementTable"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Versement</Table.HeaderCell>
            <Table.HeaderCell>Montant</Table.HeaderCell>
            <Table.HeaderCell>Règles</Table.HeaderCell>
            <Table.HeaderCell>Inscription budgétaire</Table.HeaderCell>
            <Table.HeaderCell>Date de demande</Table.HeaderCell>
            <Table.HeaderCell>Exécution comptable</Table.HeaderCell>
            <Table.HeaderCell className="menu">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {conditionsVersements && conditionsVersements.map((condition, i) => (
            <Table.Row
              key={condition.identifiant}
              onDoubleClick={() => { openAssistantVersement(i); }}
              data-testid={`condition-${i}`}
            >
              <Table.Cell data-testid="libelle">{condition.libelle}</Table.Cell>
              <Table.Cell textAlign="right">{formatMontant(condition.montant)}</Table.Cell>
              <Table.Cell>
                <div className="reglesTableau">
                  <div>
                    <div>
                      {getRegle(condition)}
                    </div>
                    <div>
                      {condition?.dateButoire && `Date butoire: ${toDateString(condition.dateButoire)}`}
                    </div>
                  </div>
                  <div className="etatsIconsTableau">
                    {renderEtatIcons(condition)}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div>{getBugetLibelle(condition?.identifiantEntiteCompta)}</div>
                <div>
                  Exercice
                  {' '}
                  {condition.annee}
                  {' '}
                  {condition.typeBudget && `(${condition.typeBudget})`}
                </div>
                <div>{getLibelleImputation(condition?.imputationsVersements?.[0])}</div>
              </Table.Cell>
              <Table.Cell>
                {toDateString(condition.dateDemande)}
              </Table.Cell>
              <Table.Cell>
                {getExecutionComptable(condition.mouvements, condition?.engagements)}
              </Table.Cell>

              <Table.Cell collapsing className="menu">
                {' '}
                <Popup
                  trigger={(
                    <Button
                      icon="bars"
                      inverted
                      compact
                      onClick={() => setOpenConditionMenuId(condition?.identifiant)}
                      data-testid={`ouvrirMenuCondition-${condition?.identifiant}`}
                    />
                  )}
                  as={Menu.Item}
                  content={(
                    <Menu fluid vertical secondary>
                      <Menu.Item
                        name="table_update"
                        onClick={() => openAssistantVersement(i)}
                        data-testid={`modifierCondition-${condition.identifiant}`}
                      >
                        Modifier
                      </Menu.Item>
                      <Menu.Item
                        name="table_remove"
                        onClick={() => handleDeleteCondition(condition?.identifiant)}
                        data-testid={`supprimerCondition-${condition.identifiant}`}
                      >
                        Supprimer
                      </Menu.Item>

                      {
                        (condition.etat === ConditionVersementEtat.VERSEMENT_A_DEMANDER
                          || condition.etat === ConditionVersementEtat.VERSEMENT_DEMANDE) && (
                          <>
                            <Menu.Item
                              name="table_envoie"
                              onClick={() => showPreparationDemande(condition)}
                              data-testid={`preparerDemande-${condition.identifiant}`}
                            >
                              Préparer la demande
                            </Menu.Item>

                            <Menu.Item
                              name="table_envoie"
                              onClick={() => handleEnvoieDemande(condition)}
                              data-testid={`envoyerDemande-${condition.identifiant}`}
                            >
                              {condition?.doitEngager ? 'Créer l\'engagement' : 'Créer le titre'}
                            </Menu.Item>
                          </>
                        )
                      }
                    </Menu>
                  )}
                  on="click"
                  open={openConditionMenuId === condition?.identifiant}
                  onClose={() => setOpenConditionMenuId(null)}
                  onOpen={() => setOpenConditionMenuId(condition?.identifiant)}
                  position="bottom right"
                  popperModifiers={{
                    // @ts-expect-error
                    preventOverflow: {
                      boundariesElement: 'offsetParent',
                    },
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row key="new" textAlign="center">
            <Table.HeaderCell onClick={() => openAssistantVersement()} colSpan="100%">
              <Button circular icon="add" compact basic size="small" data-testid="addConditionVersementButton" />
            </Table.HeaderCell>
          </Table.Row>

        </Table.Footer>
      </Table>

      {openModal && (
        <AssistantVersements
          open={openModal}
          onFinish={handleFinish}
          conditionVersement={getCurrentCondition()}
          montantPrecedentesConditions={getMontantPrecedentesConditions()}
          onClose={handleCloseModal}
          step={assistantStep}
          budgetOptions={budgetOptions}
          montantSubvention={montantSubvention}
          onLibelleDatalist={handleLibelleDatalist}
          gabaritsPj={gabaritsPj}
        />
      )}
      {!isNil(pieceDemande?.identifiantPiece)
        && (
          <Modal closeIcon open>
            <Modal.Header key="header">Versement</Modal.Header>

            <Modal.Content key="content">
              Un
              {' '}
              {LIBELLE_TYPE_PIECE[pieceDemande?.typePiece] ?? ''}
              {' '}
              a été créé.
              Souhaitez vous le visualiser ?
            </Modal.Content>

            <Modal.Actions>
              <Button
                primary
                onClick={handleOpenComptabilite}
                loading={loadingOpenComptabilite}
                data-testid="visuMouvBoutonOui"
              >
                Oui
              </Button>
              <Button
                onClick={hanldeCloseModalComptabilite}
                data-testid="visuMouvBoutonNon"
              >
                Non
              </Button>
            </Modal.Actions>
          </Modal>
        )}

      {openPreparationDemande
        && (
          <PreparationModal
            conditionVersement={currentCondition}
            subvention={subvention}
            onClose={() => setOpenPreparationDemande(false)}
            onDownloadSuccess={handleDownloadSuccess}
            mountNode={preparationModalRef?.current}
          />
        )}
    </>
  );
};

export default ConditionsVersements;
